<template>
    <div class="x-page-container" ref="resize">
        <!-- 查询区 -->
        <div class="x-page-search">
            <x-search-item label="调拨单编号" >
                <el-input v-model="search.diaoBoDanBH" size="small" />
            </x-search-item>
            <x-search-item label="申请时间" textWidth="230px" hasEnd>
                <el-date-picker v-model="search.shenQingSJBegin" size="small" type="datetime" />
                <el-date-picker v-model="search.shenQingSJEnd" size="small" type="datetime" slot="end"/>
            </x-search-item>
            <x-search-item label="状态" clearable>
                <x-selector-one v-model="search.zhuangTai" size="small" dictType="T_HUO_LIU_STATUS"/>
            </x-search-item>
            <div class="x-search-item">
                <el-button type="primary" size="mini" @click="handleSearch">搜索</el-button>
                <el-button type="primary" size="mini" @click="handleClear">清空</el-button>
            </div>
        </div>        <!-- 批量按钮区 -->
<!--        <div class="x-page-btn">
            <el-button type="success" size="mini" round @click="handleAdd">新增</el-button>
            <el-popconfirm title="数据删除后不可恢复，确认删除吗？" @confirm="handleDeleteBatch">
                <el-button type="danger" size="mini" round slot="reference">批量删除</el-button>
            </el-popconfirm>
        </div>-->
        <!-- 列表区域 -->
        <el-table ref="table" v-loading="table.loading" :data="table.data" :height="table.height" border @selection-change="handleSelectionChange">
       <!--     <el-table-column type="selection" width="40" fixed/>-->
            <el-table-column label="序号" type="index" fixedshow-overflow-tooltip/>
            <el-table-column  prop="diaoBoDanBH" label="调拨单编号" show-overflow-tooltip/>
            <el-table-column  prop="caoZuoRen" label="操作人" show-overflow-tooltip/>
            <el-table-column  prop="shenQingDeMenDian" label="申请的门店" show-overflow-tooltip/>
            <el-table-column  prop="shenQingSJ" label="申请时间" show-overflow-tooltip/>
            <el-table-column  prop="chuKuSJ" label="出库时间" show-overflow-tooltip/>
            <el-table-column  prop="caoZuoSJ" label="操作时间" show-overflow-tooltip/>
            <el-table-column  prop="shenQingHeJi" label="申请合计" show-overflow-tooltip/>
            <el-table-column  prop="yingShouHeJi" label="应收合计" show-overflow-tooltip/>
            <el-table-column  prop="yingShouJinE" label="应收金额" show-overflow-tooltip/>
            <el-table-column  prop="shiShouHeJi" label="实收合计" show-overflow-tooltip/>
            <el-table-column  prop="shiShouJinE" label="实收金额" show-overflow-tooltip/>
            <el-table-column  prop="zhuangTai" label="状态" show-overflow-tooltip>
                <x-dict-show slot-scope="{row}" :code="row.zhuangTai" dictType="T_HUO_LIU_STATUS" />
            </el-table-column>
            <el-table-column  prop="jvShouHeJi" label="拒收合计" show-overflow-tooltip/>
            <el-table-column  prop="jvShouJinE" label="拒收金额" show-overflow-tooltip/>


            <el-table-column width="250" align="center" label="操作" fixed="right" show-overflow-tooltip>
                <template slot-scope="{ row, $index }">
                    <el-button type="success" size="mini" round @click="handleToDetail(row.id)">查看</el-button>
                    <el-button v-show="row.zhuangTai ===hlZT.SQ.key " type="primary" size="mini" round @click="handleUpdate(row.id)"> 申请出库 </el-button>
                    <el-popconfirm v-if="row.zhuangTai ===hlZT.YJJ.key " title="确认后不可恢复，确认吗？" @confirm="handleConfirm(row.id)">
                        <el-button type="primary" size="mini" round slot="reference">拒收确认</el-button>
                    </el-popconfirm>
                    <el-popconfirm v-else-if="row.zhuangTai ===hlZT.BFRK.key " title="确认后不可恢复，确认吗？" @confirm="handleConfirm(row.id)">
                        <el-button type="primary" size="mini" round slot="reference">拒收确认</el-button>
                    </el-popconfirm>

                </template>
            </el-table-column>
        </el-table>
        <!-- 分页区域 -->
        <el-pagination background
                       :total="table.total"
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       layout="total, sizes, prev, pager, next, jumper"/>
        <!-- 编辑页区域 -->
        <edit ref="edit" :editor="editor" @submitted="refresh"/>
        <detail :detail="detail"/>
    </div>
</template>
<script>
    import * as service from "@/service/hlgl/DiaoBoDan";
    import XTableBase from "@/components/x/XTableBase";
    import Edit from "@/view/hlgl/sqdbgl/DiaoBoDanChuKuEdit";
    import Detail from "@/view/hlgl/dbd/DiaoBoDanDetail";
    import {TYPE_HUO_LIU_STATUS} from "@/util/constant"

    export default {
        name: "DiaoBoShenQingList",
        mixins: [XTableBase],
        components: {Edit, Detail},
        data() {
            this.refreshService = service.list;
            this.service = service;
            return {
                hlZT:TYPE_HUO_LIU_STATUS,
                search: {
                    diaoBoDanBH: '',
                    shenQingSJBegin: '',
                    shenQingSJEnd: '',
                    zhuangTai: '',
                    confirm: '',
                }
            }
        },
        methods:{
            handleConfirm(id) {
                this.service.jvShouRuKu(id).then((response) => {
                    //提示成功
                    this.$message.success(response.msg);
                    //刷新列表
                    this.refresh();
                });
            },
        }
    }
</script>

<style scoped>
</style>
