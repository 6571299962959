<template>
    <el-dialog :title="title" :visible.sync="editor.visible" @open="open" :close-on-click-modal="false"
               destroy-on-close center append-to-body width="1000px">
        <el-form ref="form" :model="form" :rules="rules" class="x-edit">
            <x-divider title="基本信息"></x-divider>
            <el-form-item label="调拨单编号" prop="diaoBoDanBH">
                {{ form.diaoBoDanBH }}
            </el-form-item>
            <el-form-item label="申请时间" prop="shenQingSJ">
                {{ form.shenQingSJ }}
            </el-form-item>
            <el-form-item label="申请合计" prop="shenQingHeJi">
                {{ form.shenQingHeJi }}
            </el-form-item>
            <x-divider title="调拨单详情"></x-divider>
            <x-selector
                    v-show="form.id==null"
                    placement="center"
                    selector="ShangPinXXSelector"
                    @selected="handleClSelect"
                    code-key="id"
                    selection
                    width="1000px">
                <el-button size="mini" type="success" round>添加商品</el-button>
            </x-selector>
            <x-row :rows="form.diaoBoDanXiangQings" :rowModel="diaoBoDanXiangQingsModel" no-add-btn no-clean-btn>

                <x-row-cell width="100" read="shangPinMC" title="商品名称"/>
                <x-row-cell width="100" read="kuCunSL" title="库存数量"/>
                <x-row-cell width="100" read="shenQingSL" title="申请数量"/>
                <x-row-cell title="出库数量" width="100" prop="shenQingSL">
                    <el-input slot-scope="{row}" v-model="row.chuKuSL" size="small" maxlength="8"/>
                </x-row-cell>
                <x-row-cell title="出库价" width="100" prop="chuKuJia" >
                    <el-input slot-scope="{row}" v-model="row.chuKuJia" size="small" maxlength="36"/>
                </x-row-cell>
                <x-row-cell width="100" read="guiGe" title="规格"/>
                <x-row-cell width="100" read="huoHao" title="货号"/>
                <x-row-cell width="100" read="tiaoMa" title="条码"/>
                <x-row-cell width="100" read="danWei" title="单位"/>

<!--                <x-row-cell title="小计" width="100">
                    <el-input slot-scope="{row}" v-model="row.chuKuHeJi" disabled size="small" maxlength="36"/>
                    &lt;!&ndash; {{row.chuKuHeJi}}&ndash;&gt;
                </x-row-cell>-->
                <x-row-cell title="备注" width="100">
                    <el-input slot-scope="{row}" v-model="row.beiZhu" size="small" maxlength="64"/>
                </x-row-cell>
            </x-row>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="save" icon="el-icon-document-add">保 存</el-button>
            <el-button @click="cancel" icon="el-icon-switch-button">取 消</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import {add, chuKuUpdate, getChuKuUpdate} from "@/service/hlgl/DiaoBoDan";
    import XEditBase from "@/components/x/XEditBase";
    import {ruleBuilder} from '@/util/validate';
    import {checkboxList} from "@/service/mdgl/MenDianXX";
    import ShangPinXXSelector from "@/view/selector/ShangPinXXSelector";
    import XUserSelector from "@/components/x/selector/XUserSelector";
    import {TYPE_HUO_LIU_STATUS} from "@/util/constant"

    export default {
        mixins: [XEditBase],
        components: {XUserSelector, ShangPinXXSelector},
        beforeCreate() {
            let {required,positiveEightTwo} = ruleBuilder;
            this.rules = {
                diaoBoDanBH: [], // 调拨单编号
                caoZuoRen: [], // 操作人
                caoZuoRenID: [], // 操作人ID
                shenQingSJ: [], // 申请时间
                chuKuSJ: [], // 出库时间
                caoZuoSJ: [], // 操作时间
                shenQingHeJi: [], // 申请合计
                yingShouHeJi: [], // 应收合计
                yingShouJinE: [], // 应收金额
                shiShouHeJi: [], // 实收合计
                shiShouJinE: [], // 实收金额
                zhuangTai: [], // 状态
                juShouHeJi: [], // 拒收合计
                juShouJinE: [], // 拒收金额
                beiZhu: [], // 备注
                chuKuJia: [required(),positiveEightTwo()], // 备注
                chuKuSL: [required(),positiveEightTwo()], // 备注
                shenQingSL: [required(),positiveEightTwo()], // 备注
            }
            this.titlePrefix = '调拨单';
            this.diaoBoDanXiangQingsModel = {
                diaoBoDanBH: "", // 调拨单编号
                shenQingSJ: "", // 申请时间
                shangPinMC: "", // 商品名称
                shangPinID: "", // 商品ID
                guiGe: "", // 规格
                huoHao: "", // 货号
                tiaoMa: "", // 条码
                danWei: "", // 单位
                shengChanRQ: "", // 生产日期
                baoZhiQiTian: "", // 保质期（天）
                shenQingSL: "", // 申请数量
                chuKuSL: "0", // 出库数量
                chuKuJia: "0", // 出库价
                chuKuJinE: "0", // 出库金额
                chuKuHeJi: "0", // 出库合计
                ruKuSL: "", // 入库数量
                ruKuJinE: "", // 入库金额
                ruKuHeJi: "", // 入库合计
                beiZhu: "", // 备注
                beiYong: "", // 备用
                guiGeJiShu: "", // 规格基数
            }
            this.defaultForm = {
                id: null,
                diaoBoDanBH: "", // 调拨单编号
                caoZuoRenID: this.$store.getters.user.id, // 操作人ID
                caoZuoRen: this.$store.getters.user.name, // 操作人
                shenQingSJ: "", // 申请时间
                chuKuSJ: "", // 出库时间
                caoZuoSJ: "", // 操作时间
                shenQingHeJi: "", // 申请合计
                yingShouHeJi: "", // 应收合计
                yingShouJinE: "", // 应收金额
                shiShouHeJi: "", // 实收合计
                shiShouJinE: "", // 实收金额
                zhuangTai: "", // 状态
                juShouHeJi: "", // 拒收合计
                juShouJinE: "", // 拒收金额
                beiZhu: "", // 备注
                subtotal: "0", //小计
                diaoBoDanXiangQings: [],
            };
            this.addService = add;
            this.updateService = chuKuUpdate;
            this.getUpdateService = getChuKuUpdate;
        },

        data() {
            return {
                hlZT: TYPE_HUO_LIU_STATUS
            }
        },
        mounted() {
            this.getCangKuOptions();
        },
        watch: {
/*            'form.diaoBoDanXiangQings': {
                handler: function(newValue, oldValue) {
                    newValue.forEach((newItem, index) => {
                        const oldItem = oldValue[index];
                        if (newItem.chuKuSL !== oldItem.chuKuSL) {
                            console.log(`chuKuSL changed in item at index ${index}`);
                            // 执行相应的逻辑
                        }
                    });
                },
                deep: true
            },*/
        },
        methods: {
            handleClSelect(cls) {
                const diaoBoDanXiangQings = this.form.diaoBoDanXiangQings;
                cls.forEach(cl => {
                    let obj = {};
                    obj.huoHao = cl.huoHao || '',
                    obj.tiaoMa = cl.tiaoMa || '',
                    obj.danWei = cl.danWei || '',
                    obj.chuKuSL = cl.shenQingSL || '',
                    obj.shenQingSL = cl.shenQingSL || '',
                    obj.shangPinID = cl.id || '',
                    obj.shangPinMC = cl.shangPinMC || '',
                    obj.guiGe = cl.ciJiGuiGeMC || '',
                    obj.shiFuDuoGuiGe = cl.shiFuDuoGuiGe || '',
                    obj.guiGeShangPinJinJia = cl.guiGeShangPinJinJia || '',
                    obj.shangPinJinJia = cl.shangPinJinJia || '',
                    diaoBoDanXiangQings.push(obj);
                });
            },
            //获取门店列表
            getCangKuOptions() {
                checkboxList().then((response) => {
                    this.options = response.data
                })
            },
            //获取商品信息列表
            change(cangKuMC) {
                getListByCangKuMC(cangKuMC).then((response) => {
                    this.spxxs = response.data
                })
            }

        }
    }
</script>

<style scoped>

</style>